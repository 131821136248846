/*
* Remove text-shadow in selection highlight:
* https://twitter.com/miketaylr/status/12228805301
*
* Vendor-prefixed and regular ::selection selectors cannot be combined:
* https://stackoverflow.com/a/16982510/7133471
*/
::-moz-selection {
  background: var(--color--highlight);
  text-shadow: none;
}

::selection {
  background: var(--color--highlight);
  text-shadow: none;
}

/*
* Remove the gap between audio, canvas, iframes,
* images, videos and the bottom of their containers:
* https://github.com/h5bp/html5-boilerplate/issues/440
*/
img,
svg,
video {
  vertical-align: middle;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  background: var(--glacier);
  color: var(--deepsea);
  margin: 0.2em 0;
  padding: 0.2em 0;
}

/* ==========================================================================
Author's custom styles
========================================================================== */

/* ----------------------------------
VARIABLES
---------------------------------- */
:root {
  /* Color variables */
  --white: #fff;
  --white--12: rgba(255, 255, 255, 0.12);
  --white--20: rgba(255, 255, 255, 0.2);
  --white--30: rgba(255, 255, 255, 0.3);
  --tundra: #f6f7f8;
  --sky: #add6ff;
  --stormcloud: #6e738c;
  --stormcloud--40: rgb(99, 104, 127, 0.3);
  --shallows: #5973a6;
  --shallows--6: rgba(25, 93, 230, 0.06);
  --shallows--16: rgba(25, 93, 230, 0.16);
  --glacier: #649bb4;
  --glacier--40: rgba(120, 173, 197, 0.4);
  --deepsea: #303240;
  --black: #000;
  --black--60: rgba(0, 0, 0, 0.6);
  --banana: #ffe599;
  --blueraspberry: #5b8ab9;
  --eggplant: #745978;
  --tomato: #ff7c7c;
  --toothpaste: #4ebdd3;

  /* Dimension variables */
  --height--header: 48px;
  --width--section: 60em;

  /* Font variables */
  --font--primary: 'Roboto';
  --font--headings: 'Roboto Condensed';
  --font--icons: 'icons';

  /* Semantic variables */
  --color--accent: var(--sky);
  --color--backdrop: var(--black--60);
  --color--background--primary: var(--tundra);
  --color--background--secondary: var(--white);
  --color--background--header: var(--shallows);
  --color--background--interactive: var(--shallows--6);
  --color--background--active: var(--shallows--16);
  --color--background--resume-panel: var(--glacier--40);
  --color--background--accent--1: var(--stormcloud);
  --color--background--accent--2: var(--glacier);
  --color--background--accent--3: var(--blueraspberry);
  --color--background--accent--4: var(--eggplant);
  --color--background--accent--5: var(--toothpaste);
  --color--background--menu--hover: var(--white--12);
  --color--background--menu--active: var(--white--20);
  --color--border--primary: var(--stormcloud--40);
  --color--border--accent: var(--white--30);
  --color--border--interactive: var(--shallows);
  --color--border--resume-section: var(--stormcloud--40);
  --color--highlight: var(--glacier--40);
  --color--text--primary: var(--deepsea);
  --color--text--secondary: var(--stormcloud);
  --color--text--header: var(--white);
  --color--text--interactive: var(--shallows);
}

/* ----------------------------------
FONTS
---------------------------------- */

/* roboto-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/roboto-v18-latin-300.eot); /* IE9 Compat Modes */
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    url(../fonts/roboto-v18-latin-300.eot?#iefix) format('embedded-opentype'),
    url(../fonts/roboto-v18-latin-300.woff2) format('woff2'),
    url(../fonts/roboto-v18-latin-300.woff) format('woff'),
    url(../fonts/roboto-v18-latin-300.ttf) format('truetype'),
    url(../fonts/roboto-v18-latin-300.svg#Roboto) format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/roboto-v18-latin-regular.eot); /* IE9 Compat Modes */
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url(../fonts/roboto-v18-latin-regular.eot?#iefix) format('embedded-opentype'),
    url(../fonts/roboto-v18-latin-regular.woff2) format('woff2'),
    url(../fonts/roboto-v18-latin-regular.woff) format('woff'),
    url(../fonts/roboto-v18-latin-regular.ttf) format('truetype'),
    url(../fonts/roboto-v18-latin-regular.svg#Roboto) format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/roboto-v18-latin-500.eot); /* IE9 Compat Modes */
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url(../fonts/roboto-v18-latin-500.eot?#iefix) format('embedded-opentype'),
    url(../fonts/roboto-v18-latin-500.woff2) format('woff2'),
    url(../fonts/roboto-v18-latin-500.woff) format('woff'),
    url(../fonts/roboto-v18-latin-500.ttf) format('truetype'),
    url(../fonts/roboto-v18-latin-500.svg#Roboto) format('svg'); /* Legacy iOS */
}

/* roboto-condensed-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/roboto-condensed-v16-latin-300.eot); /* IE9 Compat Modes */
  src:
    local('Roboto Condensed Light'),
    local('RobotoCondensed-Light'),
    url(../fonts/roboto-condensed-v16-latin-300.eot?#iefix) format('embedded-opentype'),
    url(../fonts/roboto-condensed-v16-latin-300.woff2) format('woff2'),
    url(../fonts/roboto-condensed-v16-latin-300.woff) format('woff'),
    url(../fonts/roboto-condensed-v16-latin-300.ttf) format('truetype'),
    url(../fonts/roboto-condensed-v16-latin-300.svg#RobotoCondensed) format('svg'); /* Legacy iOS */
}

/* roboto-condensed-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/roboto-condensed-v16-latin-regular.eot); /* IE9 Compat Modes */
  src:
    local('Roboto Condensed'),
    local('RobotoCondensed-Regular'),
    url(../fonts/roboto-condensed-v16-latin-regular.eot?#iefix) format('embedded-opentype'),
    url(../fonts/roboto-condensed-v16-latin-regular.woff2) format('woff2'),
    url(../fonts/roboto-condensed-v16-latin-regular.woff) format('woff'),
    url(../fonts/roboto-condensed-v16-latin-regular.ttf) format('truetype'),
    url(../fonts/roboto-condensed-v16-latin-regular.svg#RobotoCondensed) format('svg'); /* Legacy iOS */
}

/* ----------------------------------
BASICS
---------------------------------- */
html {
  box-sizing: border-box;
  font-family: var(--font--primary), sans-serif;
  font-size: 16px;
  height: 100%;
  line-height: 1.5;
  overflow-x: hidden;
  scroll-behavior: smooth; }
html.is-hidden {
  overflow-y: hidden; }

body {
  background: var(--color--background--primary);
  height: 100%;
  margin: 0;
  padding-top: var(--height--header);
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

/* ----------------------------------
GENERAL CONTENT
---------------------------------- */
main {
  position: relative;
}

section {
  color: var(--color--text--primary);
  margin: 0 auto;
  max-width: var(--width--section);
  padding: 4.5em 0 1.5625em;
}

h1,
h2,
h3 {
  font-family: var(--font--headings), sans-serif;
  font-weight: 300;
}

h1 {
  font-size: 2em;
  margin: 0 0.5rem;
}

h2 {
  font-size: 2.5em;
  line-height: 1;
  margin: 0;
  padding: 0.875rem 1rem 1.125rem;
}

h3 {
  font-size: 1.5em;
  margin: 0;
  padding: 0.125rem 0 0.0625rem;
}

h4 {
  font-family: var(--font--primary), sans-serif;
  font-weight: 500;
  margin: 1.4375em 0 0;
}

b {
  font-weight: 500;
}

p {
  margin: 0 0 1.5rem;
  max-width: 100%; /* Prevents overflow in flexed layouts in IE 11 */
}

a {
  color: var(--color--text--interactive); }
a:focus {
  outline: 1px dotted; }

figure {
  margin: 0 0 3em;
}

img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

figcaption {
  font-size: 0.875em;
  color: var(--color--text--secondary);
  padding: 0.5625rem 0 0.0625rem;
  text-align: center;
}

@media screen and (min-width: 750px) {
  section {
    display: flex;
    flex-flow: row wrap;
  }

  h2,
  h3 {
    width: 100%;
  }
}

@media screen and (min-width: 978px) {
  h1 {
    margin: 0;
  }

  h2 {
    padding: 0.875rem 0 1.125rem;
  }
}

/* ----------------------------------
BUTTONS
---------------------------------- */
button {
  background: none;
  border: 1px solid var(--color--border--interactive);
  color: var(--color--text--interactive);
  cursor: pointer;
  font: inherit;
  margin: 0.0625em 0 0;
  outline: none;
  padding: 0.25em 0.75em 0.1875em;
  position: relative;
  transition: background 0.2s, border-color 0.2s, color 0.2s;
}

button:focus::after {
  border: 1px dotted;
  bottom: 1px;
  content: '';
  left: 1px;
  position: absolute;
  right: 1px;
  top: 1px; }
html.can-touch button:focus::after {
  border: none; }

/* ----------------------------------
UTILITY CLASSES
---------------------------------- */
.u-lowEmphasis {
  color: var(--color--text--secondary);
  font-size: 0.875em;
  line-height: 1.5rem;
}

.u-border {
  border: 1px solid var(--color--border--primary);
}

.u-hero {
  color: var(--color--text--header);
  font-family: var(--font--headings), sans-serif;
  font-size: 1.25em;
  font-weight: 300;
  line-height: 1.6;
  margin: 0;
  padding: 0.75em 1em;
}

@media screen and (min-width: 750px) {
  .u-hero {
    font-size: 1.5em;
    line-height: 1.5;
  }
}

/* ----------------------------------
HEADER
---------------------------------- */
header {
  background: var(--color--background--header);
  color: var(--color--text--header);
  font-family: var(--font--headings), sans-serif;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.header-wrapper {
  align-items: center;
  display: flex;
  height: var(--height--header);
  justify-content: space-between;
  margin: 0 auto;
  max-width: var(--width--section);
}

h1 a span:last-of-type {
  display: none;
}

header a {
  color: inherit;
  display: inline-block;
  line-height: var(--height--header);
  padding: 0 0.5rem;
  text-decoration: none;
}

header nav a {
  font-size: 0.875em;
  margin: 0 0.25em;
  position: relative;
  text-transform: uppercase;
  transition: background 0.2s; }
header nav a:last-of-type {
  margin-right: 0.5em; }
header nav a:hover,
.js-focus-visible header nav a.focus-visible:focus {
  background: var(--color--background--menu--hover); }
html.can-touch header nav a:hover {
  background: none; }
header nav a:active {
  background: var(--color--background--menu--active); }

header nav a::before {
  bottom: 0;
  content: '';
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  transition: background 0.3s; }
header nav a.is-active::before {
  background: var(--color--accent); }

@media screen and (min-width: 365px) {
  header nav a {
    font-size: 1em;
    letter-spacing: 0.125em;
  }
}

@media screen and (min-width: 750px) {
  h1 a span:first-of-type {
    display: none; }
  h1 a span:last-of-type {
    display: inline; }
}

@media screen and (min-width: 978px) {
  header nav a:last-of-type {
    margin-right: 0;
  }
}

/* ----------------------------------
ABOUT
---------------------------------- */
.About {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  height: 24em;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.About img {
  filter: grayscale(100%);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.About p {
  background: var(--color--backdrop);
  padding: 0.75em 1em;
}

@media screen and (min-width: 750px) {
  .About {
    align-items: stretch;
    justify-content: flex-start;
    height: 30em;
  }

  .About p {
    max-width: 40%;
    padding: 2.875rem;
  }
}

@media screen and (min-width: 978px) {
  .About {
    margin-top: 3em;
  }

  .About img {
    left: 40%;
    width: 60%;
  }

  .About p {
    align-items: center;
    background: var(--color--background--accent--1);
    display: flex;
  }
}

/* ----------------------------------
WORK
---------------------------------- */
.Work {
  padding-bottom: 0;
}

/* ----------------------------------
CARDS
---------------------------------- */
.Card {
  background: var(--color--background--secondary);
  margin: 0 1em 1.5em;
  padding: 1em; }
.Card:last-of-type {
  margin-bottom: 0; }

.Card-image {
  background: var(--color--background--accent--2);
  background-clip: padding-box;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  height: 15em;
  margin: 0 0 2em;
  width: 100%;
}

.Card h3 {
  font-size: 1.25em;
  font-weight: 400;
}

.Card-btnHeader {
  border: none;
  color: inherit;
  margin: 0;
  padding: 0;
  text-align: left;
}

.Card ul {
  font-size: 0.875em;
  list-style: none;
  margin: 0;
  padding: 0 0 1.5625rem;
  -webkit-transform: translate3d(0, 0, 0); /* Fixes iOS bug causing touch-scrolled elements to hide siblings */
}

.Card ul li {
  color: var(--color--text--secondary);
  display: inline-block;
  padding-right: 1em;
}

.Card-btnExpand:hover,
.js-focus-visible .Card-btnExpand.focus-visible:focus {
  background: var(--color--background--interactive); }
html.can-touch .Card-btnExpand:hover {
  background: none; }
.Card-btnExpand:active {
  background: var(--color--background--active); }

@media screen and (min-width: 750px) {
  .Card {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin: 0 1.5em 1.5em;
    width: calc(100% - 3em); }
  .Card:nth-child(odd) {
    margin-right: 0; }
}

@media screen and (min-width: 850px) {
  .Card {
    margin: 0 1.5em 1.5em 0;
    width: calc(50% - 0.75em); }
  .Card:last-of-type {
    margin-bottom: 1.5em; }
}

/* ----------------------------------
FULL-SCREEN CARDS
---------------------------------- */
.Card--fullscreen {
  background: var(--color--backdrop);
  color: var(--color--text--primary);
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  opacity: 1;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  z-index: 1; }
.Card--fullscreen.is-collapsed {
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s 0.3s ease;
  visibility: hidden; }

.Card--fullscreen-inner {
  background: var(--color--background--secondary);
  margin: 0 auto;
  opacity: 1;
  position: relative;
  max-width: 70em;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  width: 100%;
  overflow-behavior-y: contain; /* stylelint-disable-line */
  -webkit-overflow-scrolling: touch; /* For iOS momentum scrolling */ }
.Card--fullscreen-inner:first-of-type {
  flex: 0 0 auto; }
.Card--fullscreen-inner:last-of-type {
  flex: 1;
  overflow: auto; }
.Card--fullscreen.is-collapsed .Card--fullscreen-inner {
  transform: translateX(100%); }

.Card--fullscreen-heroDisplay {
  background: var(--color--background--accent--2);
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-flow: column wrap;
  height: 24em;
  justify-content: flex-end;
  width: 100%;
}

.Card--fullscreen h3 {
  align-items: center;
  background: var(--color--background--accent--2);
  border-bottom: 1px solid var(--color--border--accent);
  color: var(--color--text--header);
  display: flex;
  font-size: 1.5em;
  justify-content: center;
  line-height: 1;
  min-height: 3.0625rem;
  padding: 0.5rem 3rem;
}

.Card--fullscreen-btnClose {
  border: none;
  color: var(--color--text--header);
  font-family: var(--font--icons);
  height: 3em;
  margin: 0;
  padding: 0;
  position: absolute;
  right: calc(100% - 3em);
  top: 0;
  width: 3em;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0); /* Fixes iOS bug causing touch-scrolled elements to hide siblings */
}

.Card--fullscreen-btnClose::before {
  content: '\ea40';
  display: block;
  font-size: 1.5em;
  line-height: 1;
}

.Card--fullscreen-btnClose span {
  background: var(--color--text--header);
  border-radius: 0.25em;
  display: none;
  height: 2px;
  left: 20.833%;
  position: absolute;
  top: calc(50% - 1px);
  width: 58.333%; }
.Card--fullscreen-btnClose span:nth-child(1) {
  transform: rotate(45deg); }
.Card--fullscreen-btnClose span:nth-child(2) {
  transform: rotate(-45deg); }

.Card--fullscreen h4 {
  font-family: var(--font--headings), sans-serif;
  font-size: 1.5em;
  font-weight: 300;
  margin: 3.9375rem 0 1.3125rem;
  padding: 0 1rem;
  width: 100%; }
.Card--fullscreen h4:first-of-type {
  margin-top: 2.8125rem; }

.Card--fullscreen-content {
  padding: 0; }

.Card--fullscreen p {
  padding: 0 1em; }
.Card--fullscreen .Card--fullscreen-heroDisplay p {
  background: var(--color--background--accent--2);
  padding: 1.5rem 1rem; }

.Card--fullscreen ul {
  margin: 0 0 1.5em;
  padding: 0 1em 0 3em;
}

.Card--fullscreen li {
  margin-bottom: 1.5em;
}

.Card--fullscreen figure {
  padding: 0 1em;
  width: 100%;
}

@media screen and (min-width: 750px) {
  .Card--fullscreen {
    background: none;
  }

  .Card--fullscreen-inner {
    transition: opacity 0.3s 0.2s ease, transform 0.3s 0.2s ease; }
  .Card--fullscreen.is-collapsed .Card--fullscreen-inner {
    opacity: 0;
    transform: translateY(-5em); }

  .Card--fullscreen-heroDisplay {
    background-position: 100% 0%;
    background-size: initial;
    justify-content: flex-start;
    flex-direction: row;
  }

  .Card--fullscreen h3 {
    font-size: 2em;
    padding: 1.3125rem 3.5rem 1.1875rem;
  }

  .Card--fullscreen-btnClose {
    right: 0.75em;
    top: 0.75em;
  }

  .Card--fullscreen-btnClose span {
    display: block;
  }

  .Card--fullscreen-btnClose::before {
    content: '';
  }

  .Card--fullscreen h4 {
    margin: 0;
    padding: 0.9375rem 1.5rem 1.3125rem; }
  .Card--fullscreen h4:first-of-type {
    margin: 0;
    padding-top: 2.8125rem; }

  .Card--fullscreen-content:last-of-type {
    padding-bottom: 2em; }

  .Card--fullscreen p {
    padding: 0 3em; }
  .Card--fullscreen .Card--fullscreen-heroDisplay p {
    background: none;
    max-width: 40%;
    padding: 2.8125rem 1.5rem 0; }

  .Card--fullscreen ul {
    padding: 0 3em 0 6em;
  }

  .Card--fullscreen figure {
    padding: 0 3em;
  }
}

@media screen and (min-width: 978px) {
  .Card--fullscreen {
    background: var(--color--backdrop);
    padding: 1.5em;
  }

  .Card--fullscreen-inner:last-of-type {
    display: flex;
    flex-flow: row wrap;
  }

  .Card--fullscreen-inner h4 {
    background: var(--color--background--primary);
    padding: 2.4375rem 1.5rem 1.3125rem;
    width: 25%;
  }

  .Card--fullscreen-content {
    padding: 3em 0 0;
    width: 75%; }
  .Card--fullscreen-content:first-of-type {
    padding-top: 3.375em; }
}

/* ----------------------------------
CUSTOM CARD STYLING
---------------------------------- */

/* Tutorial hero */
#Card-tutorial .Card-image {
  background-image: url(../img/hero-tutorial.png);
}

#Card-tutorial--fullscreen .Card--fullscreen-heroDisplay {
  background-image: url(../img/hero-tutorial.png);
  background-size: auto 65%;
}

/* Style guide hero */
#Card-styleguide .Card-image {
  background-color: var(--color--background--accent--3);
  background-image: url(../img/hero-styleguide.png);
}

#Card-styleguide--fullscreen h3,
#Card-styleguide--fullscreen .Card--fullscreen-heroDisplay p {
  background: var(--color--background--accent--3);
}

#Card-styleguide--fullscreen .Card--fullscreen-heroDisplay {
  background-color: var(--color--background--accent--3);
  background-image: url(../img/hero-styleguide.png);
  background-size: auto 65%;
}

/* Multimedia hero */
#Card-multimedia .Card-image {
  background-color: var(--color--background--secondary);
  background-image: url(../img/hero-multimedia.gif);
  background-position: -7em -9em;
  background-size: auto 384px;
}

#Card-multimedia--fullscreen h3,
#Card-multimedia--fullscreen .Card--fullscreen-heroDisplay p {
  background: var(--color--background--accent--1);
}

#Card-multimedia--fullscreen .Card--fullscreen-heroDisplay {
  background-color: var(--color--background--accent--1);
  background-image:
    linear-gradient(100deg, var(--color--background--accent--1) 15%, transparent 100%),
    url(../img/hero-multimedia.gif);
}

/* eReader hero */
#Card-reading-ui .Card-image {
  background-image: url(../img/hero-ereader.jpg);
  background-position: 0;
  background-size: cover;
}

#Card-reading-ui--fullscreen h3,
#Card-reading-ui--fullscreen .Card--fullscreen-heroDisplay p {
  background: var(--color--background--accent--4);
}

#Card-reading-ui--fullscreen .Card--fullscreen-heroDisplay {
  background-color: var(--color--background--accent--4);
  background-image: url(../img/hero-ereader.jpg);
}

@media screen and (min-width: 750px) {
  /* Tutorial hero */
  #Card-tutorial--fullscreen .Card--fullscreen-heroDisplay {
    background-position: 100% 50%;
    background-size: 65%;
  }

  #Card-tutorial--fullscreen .Card--fullscreen-heroDisplay p {
    max-width: 32%;
  }

  /* Style guide hero */
  #Card-styleguide--fullscreen .Card--fullscreen-heroDisplay {
    background-position: 100% 50%;
    background-size: 60%;
  }

  /* Multimedia hero */
  #Card-multimedia--fullscreen .Card--fullscreen-heroDisplay {
    background-image:
      linear-gradient(100deg, var(--color--background--accent--1) 43%, transparent 80%),
      url(../img/hero-multimedia.gif);
  }

  /* eReader hero */
  #Card-reading-ui--fullscreen .Card--fullscreen-heroDisplay {
    background-image:
      linear-gradient(100deg, var(--color--background--accent--4) 43%, transparent 90%),
      url(../img/hero-ereader.jpg);
    background-position: 111% 0%;
  }
}

/* ----------------------------------
RESUME
---------------------------------- */
.Resume {
  padding: 4.4375em 0 0;
}

.Resume section {
  background: var(--color--background--secondary);
  border-top: 1px solid var(--color--border--resume-section);
  padding: 1.1875em 1em 1.0625em;
  width: 100%; }
.Resume section:first-of-type {
  border-top-color: transparent; }

.Resume-sectionTitle {
  line-height: 1.417;
  padding-bottom: 0;
}

.Resume-header {
  cursor: pointer;
  padding-left: 1.5em;
  outline: none;
  position: relative;
  width: 100%; }
.Resume-header--nonExpandable {
  cursor: initial;
  padding: 1.25em 0 0 1.5em; }

.Resume-header::before {
  border: 1px solid var(--color--text--secondary);
  border-radius: 50%;
  content: '';
  display: block;
  height: 2.125em;
  position: absolute;
  right: 0.5em;
  top: 50%;
  transition: background 0.2s, border-color 0.2s;
  transform: translateY(-50%);
  width: 2.125em; }
.Resume-header:hover::before,
.js-focus-visible .Resume-header.focus-visible:focus::before {
  background: var(--color--background--interactive);
  border-color: var(--color--text--interactive); }
html.can-touch .Resume-header:hover::before {
  background: none;
  border-color: var(--color--text--secondary); }
.Resume-header.focus-visible:focus::before {
  box-shadow: 0 0 0 2px var(--color--background--secondary), 0 0 0 3px var(--color--border--interactive); }
.Resume-header:active::before {
  background: var(--color--background--active); }

.Resume-header::after {
  align-items: center;
  color: var(--color--text--secondary);
  content: '\ea3e';
  display: flex;
  font-family: var(--font--icons);
  height: 2.125em;
  justify-content: center;
  padding: 0.5em;
  position: absolute;
  right: 0.5em;
  top: 50%;
  transition: background 0.2s, border-color 0.2s, color 0.2s, transform 0.4s ease;
  transform: translateY(-50%);
  width: 2.125em; }
.Resume-header.is-expanded::after {
  transform: rotate(180deg) translateY(50%); }
.Resume-header:hover::after,
.Resume-header.focus-tabbed:focus::after {
  color: var(--color--text--interactive); }
html.can-touch .Resume-header:hover::after {
  color: var(--color--text--secondary); }

.Resume-header h4 {
  padding-left: 0.5em;
  padding-right: 3em;
  text-indent: -0.5em;
}

.Resume-header p,
.Resume-header--nonExpandable p {
  padding-left: 0.5em;
  padding-right: 3em;
  text-indent: -0.5em;
}

.Resume-header p:not(:last-of-type),
.Resume-header--nonExpandable p:not(:last-of-type) {
  margin-bottom: 0;
}

.Resume-list {
  background: var(--color--background--resume-panel);
  display: none;
  list-style: none;
  margin: 0 -1em;
  padding: 3em 2.5em;
  width: calc(100% + 2em);
}

.Resume-list li:not(:last-of-type) {
  padding-bottom: 1.5em;
}

@media screen and (min-width: 750px) {
  .Resume-header p:last-of-type {
    margin-bottom: 0.5625em;
  }

  .Resume-list li,
  .Resume-list li:not(:last-of-type) {
    font-size: 0.875em;
    line-height: 1.5rem;
  }
}

/* ----------------------------------
CONTACTS
---------------------------------- */
.Contacts {
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
}

.Contacts-link {
  color: inherit;
  display: inline-flex;
  margin: 0 0 1.5em 1.5em;
  text-decoration: none;
  transition: color 0.2s; }
.Contacts-link:hover,
.Contacts-link.focus-tabbed:focus {
  color: var(--color--text--interactive); }
html.can-touch .Contacts-link:hover {
  color: inherit; }

.Contacts-link::before {
  display: block;
  font-size: 1.5em;
  margin-right: 0.5em;
}

/* ----------------------------------
ICONS
---------------------------------- */
@font-face {
  font-display: block;
  font-family: 'icons';
  src: url(../fonts/icomoon.eot?1vnr2d);
  src:
    url(../fonts/icomoon.eot?1vnr2d#iefix) format('embedded-opentype'),
    url(../fonts/icomoon.woff2?1vnr2d) format('woff2'),
    url(../fonts/icomoon.ttf?1vnr2d) format('truetype'),
    url(../fonts/icomoon.woff?1vnr2d) format('woff'),
    url(../fonts/icomoon.svg?1vnr2d#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='Icon--']::before,
[class*='Icon--']::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  color: var(--color--text--interactive);
  font-family: var(--font--icons) !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  transition: color 0.2s;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Icon--mail::before {
  content: '\e159';
}
.Icon--arrow-down::before {
  content: '\ea3e';
}
.Icon--arrow-left::before {
  content: '\ea40';
}
.Icon--codepen::before {
  content: '\e900';
}
.Icon--linkedin::before {
  content: '\e901';
}
.Icon--skype::before {
  content: '\e902';
}
